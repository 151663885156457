<template>
  <certificates
    :loading="loading"
    :isDealer ="true" 
    :certificate="certificate"
    :certificatesHistory="certificatesHistory"
    :certificateHistoryTotal="certificateHistoryTotal"
    :activeCertificateGuid="activeCertificateGuid"
    :manager="manager"
    :pdfHref="pdfHref"
    :isCertificationProcess="isCertificationProcess"
    :certificationRequests="certificationRequests"
    @approve-certificate="onApprovedCertification"
    @reject-certificate="onRejectCertification"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Certificates } from '@/components'

export default {
  name: "DealerOfficeCertificates",
  components: { Certificates },
  data() {
    return {
      loading: false,
      pdfHref: ""
    }
  },
  methods: {
    ...mapActions('certificates', [
      "fetchAddressCertificate",
      "fetchAddressCertificateHistory",
      "fetchPartner",
      "isDealerInCertificationProcess",
      "fetchAddressCertificationRequests",
      "approveDealerCertificate",
      "rejectDealerCertificate"
    ]),

    onApprovedCertification(id) {
      this.loading = true;
      this.approveDealerCertificate(id).then(() => {
        this.loading = false;
        this.fetchAllData()
      });
    },
    onRejectCertification(id) {
      this.loading = true;
      this.rejectDealerCertificate(id).then(() => {
        this.loading = false;
        this.fetchAllData()
      });
    },

    fetchAllData() {      
      this.isDealerInCertificationProcess({id: this.partnerId, addressId: this.$route.params.id});  
      this.fetchAddressCertificationRequests(this.$route.params.id)  
      this.fetchAddressCertificate(this.$route.params.id).then(() => {      
        this.pdfHref = this.certificate ? process.env.VUE_APP_API + '/dealercertificates/pdf/' + this.certificate.key : null;
      });
      this.fetchAddressCertificateHistory({id: this.partnerId, addressId: this.$route.params.id});
    }
  },
  computed: {
    ...mapState({partnerId: state => state.auth.user.partnerId}),
    ...mapState('certificates', {
      isCertificationProcess: state => state.isInCertificationProcess,
      certificationRequests: state => state.newIssuanceCertificationRequest,
      certificate: state => state.addressCertificate,
      certificatesHistory: state => state.addressCertificatesHistory,
      certificateHistoryTotal: state => state.addressCertificatesHistoryTotal,
      activeCertificateGuid: state => state.activeCertificateGuid,
      manager: state => state.manufacturerManager
    })
  },
  created() {
    this.fetchAllData()
  },
  
}
</script>

<style lang="scss" scoped></style>